"use client"

import dynamic from "next/dynamic"
import * as Dialog from "@radix-ui/react-dialog"

import useGlobalNav from "@/lib/useGlobalNav"

// const SelectMasqueradePage = dynamic(
//   () => import("@/app/(user)/(auth)/masquerade/client")
// )
const TeamSettingsModal = dynamic(
  () => import("components/settings/TeamSettings"),
  {
    ssr: false,
  }
)
const AccountSettingsModal = dynamic(
  () => import("components/settings/AccountSettings"),
  { ssr: false }
)
const TeamPaymentInfoModal = dynamic(
  () => import("components/settings/TeamPaymentInfo"),
  { ssr: false }
)
const MFAModal = dynamic(() => import("components/settings/2FA"), {
  ssr: false,
})

const SingleSignOnModal = dynamic(
  () => import("components/settings/SingleSignOn"),
  {
    ssr: false,
  }
)

const OVERLAY_SCREENS = {
  MASQUERADE: () => (
    <Dialog.Root open>
      <Dialog.Portal>
        <Dialog.Overlay />
        <Dialog.Content
          className="fixed inset-0 z-[99] bg-cream text-charcoal overflow-auto"
          id="masquerade"
        >
          {/* <SelectMasqueradePage /> */}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  ),
  MY_TEAM: TeamSettingsModal,
  MY_ACCOUNT: AccountSettingsModal,
  TEAM_PAYMENT_INFO: TeamPaymentInfoModal,
  MFA_SETUP: MFAModal,
  SINGLE_SIGN_ON: SingleSignOnModal,
  TEAM_INVITE: dynamic(() => import("components/settings/TeamInvite"), {
    ssr: false,
  }),
}

export default function GlobalNavOverlay() {
  const { showOverlay } = useGlobalNav()
  const Overlay = OVERLAY_SCREENS[showOverlay] || (() => null)

  return <Overlay />
}
